import { Component } from '@angular/core';
import { BackgroundColorOptions, StatusBar, Style } from '@capacitor/status-bar';
import { Capacitor } from '@capacitor/core';
import { Router } from '@angular/router';
import { LocaldataService } from './service/localdata/localdata.service';
import { AuthService } from './service/auth/auth.service';
import { Platform } from '@ionic/angular';
import { ConfigService } from './service/config/config.service';
import { Device } from '@capacitor/device';
import { PushNotifications } from '@capacitor/push-notifications';
import { DeviceService } from './service/device/device.service';

import { Chart } from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public mainnav: boolean = false;


  constructor(private config: ConfigService, private DeviceService: DeviceService , private router: Router, private localData: LocaldataService, private auth: AuthService, private platform: Platform) {

    let version: string = localStorage.getItem('version_accepted') || '';
    let currentVersion: string = this.config.version;
    if(version==null || parseInt(currentVersion)<parseInt(version)){
      this.auth.tAndCsVisible = true;
    }

    console.log('initialize', version, currentVersion, this.auth.tAndCsVisible);

  }



  ngOnInit() {
    //console.log('APP init', this.auth.isLoggedin, this.platform);



    this.platform.ready().then(() => {
      console.log('READY');



        if (Capacitor.isPluginAvailable('StatusBar')) {
          StatusBar.setOverlaysWebView({ overlay: false });

          StatusBar.setBackgroundColor({color:'#59A8C7'});
        }

      Device.getId().then(res => {
        Device.getInfo().then(res => {
          if (res.platform !== 'web') {
            this.DeviceService.initPushNotification();
           
          }
        });
      });
      





    });
  }







  /*
  async initPushNotification_NOTINUSE() {
    console.log('LOG4');

    if (!this.platform.is('cordova')) {
      console.log('Push notifications not initialized. Cordova is not available - Run in physical device');
      return;
    }

    let permStatus = await PushNotifications.checkPermissions();

    if (permStatus.receive === 'prompt') {
      permStatus = await PushNotifications.requestPermissions();
    }

    if (permStatus.receive !== 'granted') {
      throw new Error('User denied permissions!');
    }

    //await PushNotifications.register();



    await PushNotifications.addListener('registration', token => {
      console.log('ASB Registration token: ', token.value);
      //this.auth.registerDeviceId(token.value);
    });
  
    await PushNotifications.addListener('registrationError', err => {
      console.log('ASB Registration error: ', err.error);
    });
  
    await PushNotifications.addListener('pushNotificationReceived', notification => {
      console.log('ASB Push notification received: ', notification);
    });
  


    await PushNotifications.addListener('pushNotificationActionPerformed', async (notification) => {
      console.log('Push notification action performed:', notification);
    
      const data = notification.notification.data; // Access the data payload
      
      if (data.category === 'weighins' && data.id) {
        // Navigate to the specific route with the ID
        this.router.navigate([`/vejeboner/vejebon/${data.id}`]);
      } else if(data.category === 'rentalagreement'){
        this.router.navigate([`/notifications`]);
      } else if(data.category === 'skimmelvarsling'){
        this.router.navigate([`/asb/skimmelvarsling`]);
      } else if(data.category === 'sortsinfo'){
        this.router.navigate([`/asb/sortsinfo`]);
      } else if(data.category === 'dueslag'){
        this.router.navigate([`/dueslag`]);
      } else if(data.category === 'forms'){
        this.router.navigate([`/forms`]);
      }
    });

    console.log('LOG5');
    await PushNotifications.register();   
    console.log('LOG6');





    if(!this.auth.isLoggedin){
      this.navigateTo('/login');
      console.log('NAVIGATE TO LOGIN');
      return;
    }else{
      this.localData.initData();
      this.navigateTo('/asb/home');
      console.log('NAVIGATE TO HOME');
    }

  }
*/

  navigateTo(path: string):void{
    this.router.navigateByUrl(path);
    this.mainnav = false;
  }

}







