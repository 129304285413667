import { Injectable } from '@angular/core';

interface deviceInterface {
  id?: string,
  model?: string,
  cordova?: string,
  platform?: string,
  version?: string,
  manufacturer?: string,
  serial?: string,
  isVirtual?: boolean,
};


@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  public platformName:string = "web";
  public deviceinfo: deviceInterface = {};
  public _isDev = window.location.port.indexOf('8100') > -1 || window.location.port.indexOf('8101') > -1;
  //public apiURI = this._isDev ? 'http://localhost:3000' : 'https://selvbetjening.avlerinfo.dk:3333';

  public apiURI = 'https://selvbetjening.avlerinfo.dk:3333';

  public version:string = '4.2.1';

  public basePath: string = this.apiURI;

  constructor() {}

}